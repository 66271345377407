import { useEffect, useState } from 'react';
import { BiSolidFilePdf } from "react-icons/bi";
import { APIEndpoint } from '../../../config/apiendpoint';
import AppServices from '../../../services/appServices';
import ReportPdfModel from "../../component/reportPdfModel/reportPdfModel"
import { convertDate } from '../../utils/dateHelper';
import './styles.css';
import { TailSpin } from 'react-loader-spinner';


function ReportArchives({ patientData, updateReport }) {
  const _appServices = new AppServices();
  const [loading,setLoading] = useState(true);
  const [pdfDataLoading,setPdfDataLoading] = useState(false)

  useEffect(() => {
    getReportArchivesAnalysis()
  }, [updateReport])
  const [reportArchives, setReportArchives] = useState([]);
  const [assessmentData, setAssessmentData] = useState(null);
  const [showAssessPdfModal, setShowAssessPdfModal] = useState(false);
  const handleAssessmentClose = () => {
    setShowAssessPdfModal(false);
  };
  const downloadPdf = async (selectedRow) => {
    setPdfDataLoading(true)
    
    setAssessmentData(null)
    try {
      const resp = await _appServices.getData(
        `${APIEndpoint.getReportPdfData}/${selectedRow.id}?patientUid=${patientData?.uuid}`
      );
      if (resp.status === 200 && resp.success == true) {
        setShowAssessPdfModal(true);
        setPdfDataLoading(false)
        switch (resp.data.measurement) {
          case 'Blood Pressure': {
            const modifyData = resp.data.readingDatas.map(item => ({
              date: item.dateTime,
              range: `${item.systolic}/${item.diastolic}`,
              systolic: item.systolic,
              diastolic: item.diastolic,
              device: item.deviceType,
              unit: item.bloodPressureUnits,
              isAbnormalDiastolic: item.isAbnormalDiastolic,
              isAbnormalSystolic: item.isAbnormalSystolic,
              danger: item.isAbnormalDiastolic || item.isAbnormalSystolic
            }));
            resp.data.readingDatasPressure = modifyData;
            break;
          }
          case 'Blood Glucose Level': {
            const modifyData = resp.data.readingDatas.map(item => ({
              date: item.dateTime,
              range: item.glucoseReading,
              isAbnormalGlucoseReading: item.isAbnormalGlucoseReading,
              device: item.device ? item.deviceType : 'Accu-Chek',
              unit: item.readingUnit
            }));
            resp.data.readingDatasGlucose = modifyData;
            break;
          }
          default:
            // Handle other cases or a default action if needed
            console.warn('Unknown measurement type:', resp.data.measurement);
        }

        setAssessmentData(resp.data);
      }
      _appServices.logData({
        Severity: 'Info',
        Activity: `User viewed completed Report archive pdf.`,
        ActivityStatus: 'Success',
        ActivityResponse: 'Page View',
        Operation: 'User Activity',
      })
    } catch (error) {
      console.error("Error fetching datad:", error);
      setPdfDataLoading(false)
      _appServices.logData({
        Severity: 'Error',
        Activity: `User failed to view completed Report archive pdf.`,
        ActivityStatus: 'Failure',
        ActivityResponse: 'Page View',
        Operation: 'User Activity',
      })
    }
  };


  const getReportArchivesAnalysis = async () => {
    try {
      const resp = await _appServices.getData(`${APIEndpoint.reportArchives}?patientUid=${patientData?.uuid}`);
      if (resp?.status === 200 && resp?.success == true) {
        let data = resp.data
        let addLogDatas = []

        if (data && data.logDatas.length) {
          data.logDatas.map((item) => {
            addLogDatas.push({
              cyclePeriod: `${convertDate(item.cycleStartDate)} - ${convertDate(item.cycleEndDate)}`,
              measurement: item?.analysis,
              reportLink: '#',
              id: item.uuid,
              item: item
            })
          })
          setReportArchives(addLogDatas)
        }
        setLoading(false)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)
    }
  }
  return (
    <div className="graph-container">
      <h2 className='graphHeading ml-5 mb-4'>Report Archives</h2>
      <div className="bordertable-container ml-5">
        <table className="borderdata-table">
          <thead>
            <tr>
              <th>30-day Cycle Period</th>
              <th>Measurement</th>
              <th>Download/View Report</th>
            </tr>
          </thead>
          <tbody>
            {reportArchives.length ? reportArchives.map((entry, index) => (
              <tr key={index}>
                <td>{entry.cyclePeriod}</td>
                <td>{entry.measurement}</td>
                <td>
                 {!pdfDataLoading ? <BiSolidFilePdf onClick={() => downloadPdf(entry)}
                    size={30}
                    color='#000000'
                    style={{ alignItems: 'center', marginLeft: 50, cursor: 'pointer' }}
                  />:<div style={{ alignItems: 'center', marginLeft: 50, cursor: 'pointer' }}> <TailSpin
                  visible={true}
                  height="20"
                  width="20"
                  color="#03A84E"
                  ariaLabel="tail-spin-loading"
                  radius="1"
              /> </div>}
                </td>
              </tr>
            )) : ''}
          </tbody>
        </table>
        {
          loading == true && <div className="d-flex align-items-center justify-content-center pt-5">
          <TailSpin
              visible={true}
              height="40"
              width="40"
              color="#03A84E"
              ariaLabel="tail-spin-loading"
              radius="1"
          />
      </div>
        }
        {(reportArchives.length === 0  && loading == false) &&  (
          <div className="d-flex align-items-center justify-content-center pt-2">
            <p className="graphText thin-heading">No Data Found</p>
          </div>
        )}
      </div>
      <ReportPdfModel
        showModal={showAssessPdfModal}
        data={assessmentData}
        handleClose={handleAssessmentClose}
      />
    </div>
  );
}

export default ReportArchives;
